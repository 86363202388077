import React from "react"
import MinimalHero from '../modules/MinimalHero/MinimalHero';

const Home = ({ data }) => {

  return (
    <>
      <div className="home">
        {/* Need 'headings' to also contain url? */}
        <MinimalHero headings={['Seesaw', 'Studio']} mouseDirection="" />
        {/* <LinkBlock /> */}
      </div>
    </>
  )
}

export default Home;