import React, { useEffect, useRef } from 'react';
import ImgLayering from '../../utils/imgLayering';
import './ImageLayering.scss';
import AW20 from '../../images/layers/AW20.gif';
import AW21 from '../../images/layers/AW21.gif';

const ImageLayeringLive = ({scrollSpeed = '-2', id = null, intervalSpeed = 1100}) => {
  const imagesRef = useRef(null);

  useEffect(() => {
    const imageLayering = new ImgLayering(imagesRef.current);
    imageLayering.intervalSpeed = intervalSpeed;

    if(id) {
      window[id] = imageLayering;
    } else {
      imageLayering.start();
    }

    function preFetchImages() {
      let images = document.querySelectorAll('.image_layering__media');
      let videos = document.querySelectorAll('.image_layering__media.video');
      images = [...images];
      images.reverse();

      videos = [...videos];
      videos.reverse();

      videos.forEach((video, index) => {
        setTimeout(() => {
          if(!video.classList.contains('loaded')) {
            video.querySelector('video').setAttribute('src', video.dataset.src);
            video.classList.add('loaded');
          }
        }, (imageLayering.intervalSpeed) * index)
      });

      images.forEach((image,index) => {
        setTimeout(() => {
          const nextImage = image.previousSibling;

          if(nextImage && !nextImage.classList.contains('loaded')) {
            if(!nextImage.classList.contains('video')) {
              nextImage.setAttribute('src', nextImage.dataset.src);
            }

            nextImage.classList.add('loaded');

            const nextNextImage = nextImage.previousSibling;

            if(nextNextImage && !nextNextImage.classList.contains('loaded')) {
              if(!nextNextImage.classList.contains('video')) {
                nextNextImage.setAttribute('src', nextNextImage.dataset.src);
              }

              nextNextImage.classList.add('loaded');
            }
          }

        },imageLayering.intervalSpeed * index);
      });
    }

    preFetchImages();

  },[imagesRef]);

  return (
    <div className="image_layering" data-scroll data-scroll-speed={scrollSpeed}>
      <div ref={imagesRef} className="image_layering__images">
        <img className="image_layering__media" src="../../images/layers/sq.png" data-src="../../images/layers/AW30.png" alt="" placeholder="none"/>
        <img className="image_layering__media" src="../../images/layers/sq.png" data-src="../../images/layers/AW29.png" alt="" placeholder="none"/>
        <div className="image_layering__media video video-AW28" data-src="https://player.vimeo.com/progressive_redirect/playback/731178485/rendition/540p/file.mp4?loc=external&signature=85565a28c28b18a5ebb7e1e34a30cf3cbb376e97799d9cd907011e7e0f561daa">
          <video playsInline muted loop autoPlay>
            <source src="" type="video/mp4" />
          </video>
        </div>
        <img className="image_layering__media" src="../../images/layers/sq.png" data-src="../../images/layers/AW27.png" alt="" placeholder="none"/>
        <img className="image_layering__media" src="../../images/layers/sq.png" data-src="../../images/layers/AW26.png" alt="" placeholder="none"/>
        <img className="image_layering__media" src="../../images/layers/sq.png" data-src="../../images/layers/AW25.png" alt="" placeholder="none"/>
        <img className="image_layering__media" src="../../images/layers/sq.png" data-src="../../images/layers/AW24.png" alt="" placeholder="none"/>
        <div className="image_layering__media video video-AW23" data-src="https://player.vimeo.com/progressive_redirect/playback/731209975/rendition/540p/file.mp4?loc=external&signature=26dfcb16a43daf7e05abc1c0cd5216aa0949702379bb92bb12758517ca6a92de">
          <video src="" playsInline muted loop autoPlay />
        </div>
        <img className="image_layering__media" src="../../images/layers/sq.png" data-src="../../images/layers/AW22.png" alt="" placeholder="none"/>

        <div className="image_layering__media gif gif-AW21">
          <img src={AW21} alt="" placeholder="none"/>
        </div>
        <div className="image_layering__media gif gif-AW20">
          <img src={AW20} alt="" placeholder="none"/>
        </div>
        <img className="image_layering__media" src="../../images/layers/sq.png" data-src="../../images/layers/AW19.png" alt="" placeholder="none"/>
        <div className="image_layering__media video" data-src="https://player.vimeo.com/progressive_redirect/playback/731178207/rendition/540p/file.mp4?loc=external&signature=5c4bdf02c55475fafeef3e17ac10570553b8a7d631dadc10a73cdf20abd2d706">
          <video playsInline muted loop autoPlay />
        </div>
        <img className="image_layering__media" src="../../images/layers/sq.png" data-src="../../images/layers/AW17.png" alt="" placeholder="none"/>
        <img className="image_layering__media" src="../../images/layers/sq.png" data-src="../../images/layers/AW16.png" alt="" placeholder="none"/>
        <div className="image_layering__media video" data-src="https://player.vimeo.com/progressive_redirect/playback/731178158/rendition/540p/file.mp4?loc=external&signature=4474685e5f804abe771d5adfae3c29b78a10d355959958c1bcc0501ab72504d3">
          <video src="" playsInline muted loop autoPlay />
        </div>
        <img className="image_layering__media" src="../../images/layers/sq.png" data-src="../../images/layers/AW14.png" alt="" placeholder="none"/>
        <img className="image_layering__media" src="../../images/layers/sq.png" data-src="../../images/layers/AW13.png" alt="" placeholder="none"/>
        <img className="image_layering__media" src="../../images/layers/sq.png" data-src="../../images/layers/AW12.png" alt="" placeholder="none"/>
        <img className="image_layering__media" src="../../images/layers/sq.png" data-src="../../images/layers/AW11.png" alt="" placeholder="none"/>
        <img className="image_layering__media" src="../../images/layers/sq.png" data-src="../../images/layers/AW10.png" alt="" placeholder="none"/>
        <img className="image_layering__media" src="../../images/layers/sq.png" data-src="../../images/layers/AW9.png" alt="" placeholder="none"/>
        <img className="image_layering__media" src="../../images/layers/sq.png" data-src="../../images/layers/AW8.png" alt="" placeholder="none"/>
        <img className="image_layering__media" src="../../images/layers/sq.png" data-src="../../images/layers/AW7.png" alt="" placeholder="none"/>
        <img className="image_layering__media" src="../../images/layers/sq.png" data-src="../../images/layers/AW6.png" alt="" placeholder="none"/>
        <img className="image_layering__media" src="../../images/layers/sq.png" data-src="../../images/layers/AW5.png" alt="" placeholder="none"/>
        <img className="image_layering__media" src="../../images/layers/sq.png" data-src="../../images/layers/AW4.png" alt="" placeholder="none"/>
        <img className="image_layering__media loaded" src="../../images/layers/AW3.png" data-src="../../images/layers/AW3.png" alt="" placeholder="none"/>
        <img className="image_layering__media loaded" src="../../images/layers/AW2.png" data-src="../../images/layers/AW2.png" alt="" placeholder="none"/>
        <div className="image_layering__media video loaded">
          <video src="https://player.vimeo.com/progressive_redirect/playback/731178125/rendition/540p/file.mp4?loc=external&signature=ba0d373654aff9d3c530e7ac590f8c2d5c771f956d069e39f509c2d91890ec5f" playsInline muted loop autoPlay />
        </div>
      </div>
    </div>
   );
}

export default ImageLayeringLive;