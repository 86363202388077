
export default class ImgLayering {
	constructor(parent) {
		this.parent = parent;
		this.images = [];
		this.isPlaying = false;
		this.imageCount = 0;
		this.interval = null;
		this.initialInterval = 0;
		this.intervalSpeed = 900;

		this.getImages();
		this.current = this.images[0];

		this.assignIndex();
		this.eventListeners();

	}

	eventListeners() {
		this.parent.addEventListener('mouseenter', this.stop.bind(this));
		this.parent.addEventListener('mouseleave', this.start.bind(this));
	}

	getImages() {
		this.images = this.parent.querySelectorAll('.image_layering__media');
		this.imageCount = this.images.length;
	}

	assignIndex() {
		this.images.forEach((image, index) => {
			image.style.zIndex = index + 1;

			if (index === this.imageCount - 1) {
				image.classList.add('init');
			}

		});
	}


	updateIndexes() {
		this.images.forEach((image,index) => {
			const imgIndex = parseInt(image.style.zIndex);
			if (imgIndex !== this.imageCount) {
				image.style.zIndex = imgIndex + 1;

				if (imgIndex === this.imageCount - 1) {
					this.current = image;
				}
			} else {
				image.style.zIndex = 1;
			}

			if(imgIndex === 6) {
				image.classList.remove('init');
			}

			if(imgIndex === this.imageCount - 1 && !image.classList.contains('init')) {
				this.images[index].classList.add('init');
			}
		});
	}


	start() {
		if(!this.isPlaying) {
			this.isPlaying = true;
			this.interval = setInterval(() => {
				this.updateIndexes();
				if(this.current.classList.contains('video')) {
					this.stop();
					setTimeout(() => {
						this.start();
					}, 4000);
				}

			}, this.intervalSpeed);
		}
	}

	stop() {
		if(this.isPlaying) {
			this.isPlaying = false;
			clearInterval(this.interval);
		}
	}

}